<template>
  <div class="pageMain">
    <search-head :model="searchForm" @search="search" @reset="reset">
      <el-form :inline="true" :model="searchForm">
        <el-form-item label="机构名称">
          <el-input v-model="searchForm.orgaName" size="small" placeholder="公司名称"></el-input>
        </el-form-item>
      <!-- <el-form-item label="项目名称">
        <el-select v-model="searchForm.region" size="small" placeholder="项目名称">
          <el-option label="区域一" value="shanghai"></el-option>
          <el-option label="区域二" value="beijing"></el-option>
        </el-select>
      </el-form-item> -->
    </el-form>
    </search-head>
    <custom-tables :data="tableData"
        :headerCellStyle="headerCellStyle"
        @selectionChange="handleSelectionChange"
        @handleCurrentChange="handleCurrentChange"
      @handleSizeChange="handleSizeChange"
      
        :total="total"
        title="组织机构">
      <!-- <template slot="btns">
        <el-button type="primary" size="small" @click="addFrom" icon="el-icon-plus" class="addBtn">新增</el-button>
      </template> -->
      <el-table-column type="selection" align="center" width="55">
    </el-table-column>
      <el-table-column
          prop="orgaName"
          label="机构名称"
          align="center"
          show-overflow-tooltip
        >
      </el-table-column>
      <el-table-column
          prop="fatherName"
          align="center"
          label="上级机构"
        >
      </el-table-column>
      <el-table-column
          prop="orgaLevel"
          align="center"
          label="机构层级">
      </el-table-column>
      <!-- <el-table-column
          prop="orgaLevel"
          align="center"
          label="机构地址">
      </el-table-column> -->
      <el-table-column
          prop="orgaSort"
          align="center"
          label="顺序">
      </el-table-column>
      <el-table-column
          prop="isEnable"
          align="center"
          label="状态">
          <template slot-scope="scope">
          <el-tag v-if="scope.row.isEnable == 0">启用</el-tag>
          <el-tag v-if="scope.row.isEnable == 1" type="danger" >禁用</el-tag>
        </template>
      </el-table-column>
      <!-- <el-table-column
          prop="address"
          align="center"
          label="创建时间">
      </el-table-column> -->
      <!-- <el-table-column label="操作" align="center" width="100">
          <template slot-scope="scope">
            <span
              @click="detailItem(scope.row)"
              class="detail"
            >
            <i class="el-icon-edit"></i>
          </span>
          <span
              @click="deleteItem(scope.row)"
              class="detail delete"
            >
            <i class="el-icon-delete"></i>
          </span>
          </template>
        </el-table-column> -->
    </custom-tables>

    <custom-dialog :visible="showDialog" title="新增控制" @close="close" @submit="submit">
      <div>弹出框</div>
    </custom-dialog>
  </div>
</template>

<script>
import SearchHead from "../../../components/common/SearchHead";
import CustomTables from "../../../components/common/CustomTables";
import CustomDialog from "../../../components/common/CustomDialog";
import sysManage from '../../../api/modules/sysManage'
export default {
  name: "index",
  components: {CustomDialog, CustomTables, SearchHead},
  data() {
    return {
      tableData: [
       
      ],
      searchForm:{
          currPage: 1,
        pageSize:10
        },
      total:0,
      showDialog: false,
      headerCellStyle: {
      backgroundColor: "#f3f2f7",
      color: "#373628",
    },
    }
  },
  mounted() {
    this.getList()
  },
  methods:{
    getList(){
      sysManage.GetOrganizationList(this.searchForm).then(res=>{
        this.total = res.data.total
      this.tableData = res.data.list
      })
    },
    search(){
      this.searchForm.currPage = 1;
      this.searchForm.pageSize = 10;
      this.getList();
    },
    reset(){
      this.searchForm = {
        currPage: 1,
        pageSize: 10,
      };
      this.getList();
    },
    addFrom(){
      this.showDialog = true
    },
    close(){
      this.showDialog = false
    },
    submit(){
      this.showDialog = false
    },
    detailItem(){
      //详情
  },
  deleteItem(){

  },
  handleSelectionChange(val){
      console.log(val);
      
  },
  handleCurrentChange(pageIndex) {
      this.searchForm.currPage = pageIndex
      this.getList()
    },
    handleSizeChange(pageSize) {
      this.searchForm.pageSize = pageSize
      this.searchForm.currPage = 1
      this.getList()
    },

  }
}
</script>

<style scoped lang="less">
.detail{
  color: #008f4d;
}
.delete{
  margin-left: 10px;
}
.addBtn{
  background-color: #008f4d;
}
</style>